.Container {
    display: grid;
    grid-template: repeat(6, 1fr) / repeat(8, 12.5%);
    height: 100vh;
}

.ContentContainer {
    grid-area: 3 / 2 / 8 / 8;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
}

.Header {
    grid-area: 2 / 3 / 3 / 7;
    text-align: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
}


.Container h1 {
    color: #cacaca;
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 0;
    display: block;
}

.Container h2 {
    font-size: 20px;
    line-height: 165%;
    font-weight: 400;
}


@media (max-width: 1200px) {
    .ContentContainer {
        grid-area: 3 / 3 / 8 / 7;
    }
}

@media (max-width: 801px) {
    .Header {
        grid-area: 1 / 3 / 2 / 7;
    }

    .ContentContainer {
        grid-area: 2 / 3 / 8 / 7;
    }
}

@media (max-width: 769px) {
    .Subheader {
        display: none;
    }
}

@media (max-width: 501px) {
    .ContentContainer {
        grid-area: 2 / 1 / 7 / 9;
    }
}