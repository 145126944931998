.Container {
    width: 75%;
    margin: auto;
    height: auto;
}

.Carousel {
    /* border: 2px solid rgba(255, 255, 255, 0.15); */
    border-radius: 3px;
    width: 100%;
    height: 100%; /* self defined as needed*/
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
}


/* Change Width to equal (amount of content * 100%)*/

.Sliders {
    height: 100%;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 10px;
    /* border: 3px solid red; */
}

.Controls span {
    position: absolute;
    top: 50%;
    cursor: pointer;
}

/* Variable */

.ArrowLeft {
    left: 10px;
}

.ArrowRight {
    right: 10px;
}

.Controls ion-icon {
    font-size: 45px;
    transform: translateY(-50%);
    transition: color 0.3s;
}

.Controls ion-icon:hover {
    color: #96fae8;
}

.Controls ul {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.Controls ul li {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 15px;
    background-color:#eeeeee;
    cursor: pointer;
    transition: all 0.3s
}

.Controls ul li.Selected {
    background-color: #96fae8;
}


@media (max-width: 1001px) {
    .Container {
        width: 100%;
    }
}