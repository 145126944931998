.ToggleButton {
	width: 75px;
	height: 75px;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	border-radius: 50%;
	align-items: center;
	padding: 10px 0;
	box-sizing: border-box;
	cursor: pointer;
	background-color: #2a2a2a00;
	margin: 25px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2000;
	transform: rotate(0deg);
	transition: transform 0.3s 0.15s;
}

.ToggleButton.Open {
	transform: rotate(-90deg)
}

.ToggleButton div {
	height: 2px;
	margin: 3px;
	background-color: #cacaca;
	border-radius: 1px;
}

.Line1 {
	width: 75%;
}

.Line2 {
	width: 75%;
	transition: width 0.3s;
}

.Line2.Shrink {
	width: 50%;
}

.Line3 {
	width: 75%;
	transition: width 0.3s;
}

.Line3.Shrink {
	width: 25%;
}

@media (max-width: 601px) {
	.ToggleButton {
		background-color: rgba(0, 0, 0, 0.25);
	}
}

@media (max-width: 401px) {
	.ToggleButton {
		height: 60px;
		width: 60px;
	}
}