.Menu {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: #3a3a3a;
    box-sizing: border-box;
    transition: transform 0.5s cubic-bezier(.33,.74,.45,.96);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Menu ul {
    list-style: none;
    width: 500px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
}

.Menu li {
    font-size: 36px;
    font-weight: 700;
    width: 100%;
    height: 15vh;
    background-color: #353535;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: color 0.3s;
    color: #9f9f9f;
}

.Menu li:hover {
    color: #cacaca;
    cursor: pointer;
}

.Open {
    transform: translateY(0);
}

.Close {
    transform: translateY(-100%);
}


@media (max-width: 601px) {
    .Menu ul {
        width: 380px;
    }
}

@media (max-width: 501px) {
    .Menu ul {
        width: 350px;
    }
}

@media (max-width: 401px) {
    .Menu ul {
        width: 300px;
    }

    .Menu li {
        font-size: 24px;
    }
}

@media (max-width: 351px) {
    .Menu ul {
        width: 150px;
    }
}