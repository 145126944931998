.Modal {
    position: absolute;
    background-color: #2a2a2a;
    border-radius: 3px;
    transition: all 0.2s;
    width: 100%;
    height: 100%;
    z-index: 800;
    transform: translate(100%);
}

.Modal.Show {
    transform: translate(0);
}

.Modal h3 {
    text-align: center;
}




