.ProjectContainer {
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 40px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 40px;
}

.Title {
    color: #cacaca;
    /* text-align: center; */
}

.ImageContainer {
    position: relative;
    width: 800px;
    height: auto;
}

.Gif,
.Still {
    height: 450px;
}

.Still:hover,
.Still.Hidden {
    opacity: 0;
}

.Still {
    position: absolute;
    z-index: 100;
    transition: all 0.2s;
}

.Description {
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;;
    /* border: 1px #7f7f7f solid; */
    border-radius: 3px;
    /* padding: 5px 1px 20px 1px; import when flex wrapped*/
}

.TextDescription {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.Description p {
    height: 90%;
    font-size: 20px;
    line-height: 148%;
    margin: 0px 25px 5px 25px;
}

.Description ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}

.Description li {
    font-size: 34px;
    border: none;
    color: #cacaca;
    transition: color 0.3s;
    cursor: pointer;
    margin: 0 10px;
}

.Description a {
    color: #cacaca;
    transition: color 0.3s;
    cursor: pointer;
}

.GithubContainer {
    transform: translateY(-5%)
}

.Github {
    font-size: 30px;
    margin-bottom: 10px;
}


.Description li:hover,
.Description li:active,
.Description .Demo:hover,
.Description .Demo:active {
    color: #96fae8;
}

.Github:hover,
.Github:active {
    color: #c9510c;
}

@media (max-width: 1440px) {
    .ImageContainer {
        width: 600px;
    }
    
    .Gif,
    .Still {
        height: 337.5px;
    }
}

@media (max-width: 1200px) {
    .ProjectContainer {
        flex-flow: wrap;
    }


    .Title {
        text-align: center;
    }

    .ImageContainer {
        margin-bottom: 16px;
    }

    .Description ul {
        margin-top: 15px;
    }

}

@media (max-width: 651px) {
    .ImageContainer {
        width: 400px;
    }
    
    .Gif,
    .Still {
        height: 225px;
    }

    .Description p {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .ImageContainer {
        width: 350px;
    }

    .Gif,
    .Still {
        height: 196.875px;
    }
}

@media (max-width: 351px) {
    .ImageContainer {
        width: 325px;
    }

    .Gif,
    .Still {
        height: 182.8125px;
    }
}

@media (max-width: 326px) {
    .ImageContainer {
        width: 275px;
    }

    .Gif,
    .Still {
        height: 154.69px;
    }
}