.Container {
    display: grid;
    grid-template: repeat(6, 1fr) / repeat(8, 12.5%);
    height: 100vh;
}

.ContentContainer {
    grid-area: 3 / 2 / 7 / 8;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
}

.Header {
    grid-area: 2 / 3 / 3 / 7;
    text-align: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
}


.Container h1 {
    color: #cacaca;
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 0;
    display: block;
}

.Container h2 {
    font-size: 20px;
    line-height: 165%;
    font-weight: 400;
}

.Form {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    position: relative;
    overflow-x: hidden;
}

.Social {
    margin-top: 15%;
    z-index: 900;
}

form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

form ion-icon {
    font-size: 24px;
    color:#cacaca;
    margin-right: 5px;
}

form span {
    display: flex;
    align-items: center;
}

.MessageSpan {
    align-items: flex-start;
}

.MessageSpan ion-icon {
    transform: translateY(50%);
}


.Input,
.Text,
.Submit {
    font-family: inherit;
    border-radius: 5px;
    background-color:#dddddd;
    outline: none;
    margin: 10px;
    font-size: 20px;
    border: 2px solid transparent;
}

.Submit {
    transition: all 0.3s;
    cursor: pointer;
    padding: 5px 10px;
}

.Input:focus,
.Text:focus,
.Submit:hover {
    background-color:#cacaca;
}

.Input,
.Text {
    padding: 8px;
    width: 500px;
    box-shadow: 0 6px 6px rgba(27, 27, 27, 0.75);
}

.Text {
    height: 150px;
    resize: none;
}

@media (max-width: 1200px) {
    .ContentContainer {
        grid-area: 3 / 3 / 8 / 7;
    }
}

@media (max-width: 801px) {
    .Header {
        grid-area: 1 / 3 / 2 / 7;
    }

    .ContentContainer {
        grid-area: 2 / 3 / 8 / 7;
    }
}

@media (max-width: 769px) {
    .Subheader {
        display: none;
    }
}

@media (max-width: 601px) {
    .Input,
    .Text {
    width: 400px;
    }
}

@media (max-width: 501px) {
    .Input,
    .Text {
    width: 300px;
    }
}

@media (max-width: 401px) {
    .Input,
    .Text {
    width: 250px;
    }
}